import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class SecurityCamerasClarificationText extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="cookiePolicy main-modal">
        <h2>CBC ENDÜSTRİ SAN. TİC. LTD. ŞTİ.</h2>
        <h2>{t("camera-1")}</h2>
        <div className="content">
          <p style={{ marginBottom: "20px" }}>{t("camera-2")}</p>
          <p style={{ marginBottom: "20px" }}>{t("camera-3")}</p>
          <p style={{ marginBottom: "20px" }}>{t("camera-4")}</p>
          <p style={{ marginBottom: "20px" }}>{t("camera-5")}</p>
          <p style={{ display: "inline" }}>{t("camera-6")}</p>
          <Link to="mailto:kvkk@cbcendustri.com" className="link">
            kvkk@cbcendustri.com
          </Link>
          <p style={{ display: "inline" }}>{t("camera-7")}</p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SecurityCamerasClarificationText);
