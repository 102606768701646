import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import alertify from "alertifyjs";
import $ from "jquery";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      to_email: "",
      from_name: "",
      email: "",
      title: "",
      message: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  sendEmail = (e) => {
    e.preventDefault();

    const { t } = this.props;

    const params = {
      to_email: "karapinarabdullahenes@gmail.com",
      from_name: this.state.from_name,
      email: this.state.email,
      title: this.state.title,
      message: this.state.message,
    };

    if (
      $("#name").val() === "" ||
      $("#mail").val() === "" ||
      $("#subject").val() === "" ||
      $("#message").val() === "" ||
      !$("#mail").val().trim().includes("@")
    ) {
      alertify.warning(t("form-warning"), 10);
    } else {
      $("#send-btn").prop("disabled", true);
      emailjs
        .send(
          "service_tzn7e3q",
          "template_02l74z5",
          params,
          "_P5AJePbXxcNJbCih"
        )
        .then(
          () => {
            $("#name").val("");
            $("#mail").val("");
            $("#subject").val("");
            $("#message").val("");
            $("#send-btn").prop("disabled", false);
            alertify.success(t("form-success"), 10);
          },
          (error) => {
            $("#send-btn").prop("disabled", false);
            alertify.error(t("form-error") + error.text, 10);
          }
        );
    }
  };

  render() {
    const { t } = this.props;
    return (
      <form onSubmit={this.sendEmail}>
        <div className="name-mail">
          <div className="name">
            <label htmlFor="name">{t("name-last")}</label>
            <input
              type="text"
              id="name"
              name="from_name"
              value={this.state.from_name}
              onChange={this.handleChange}
            />
          </div>
          <div className="mail">
            <label htmlFor="mail">{t("mail")}</label>
            <input
              type="email"
              id="mail"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="subject">
          <label htmlFor="subject">{t("subject")}</label>
          <input
            type="text"
            id="subject"
            name="title"
            value={this.state.title}
            onChange={this.handleChange}
          />
        </div>
        <div className="message">
          <label htmlFor="message">{t("message")}</label>
          <textarea
            id="message"
            name="message"
            value={this.state.message}
            onChange={this.handleChange}
          ></textarea>
        </div>
        <button type="submit" id="send-btn">
          <p>{t("send")}</p>
        </button>
      </form>
    );
  }
}

export default withTranslation()(Form);
