import { Route, Routes } from "react-router";
import Footer from "../footer/Footer";
import Navigation from "../navi/Navigation";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Homepage from "../pages/Homepage";
import Machine from "../pages/Machine";
import References from "../pages/References";
import NotFound from "../pages/NotFound";
import ScrollTop from "../scrollTop/ScrollTop";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import Preloader from "../preloader/Preloader";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const [isloading, setIsLoading] = useState(true);
  useEffect(() => {
    const fakeDataFetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };
    fakeDataFetch();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return isloading ? (
    <Preloader />
  ) : (
    <div>
      <Navigation />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="about" element={<About />} />
        <Route path="machine-track" exct element={<Machine />} />
        <Route path="references" element={<References />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <ScrollTop />
    </div>
  );
}

export default App;
