import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import imgCNC from "../../img/MachineCNC.webp";
import imgCNCVertical from "../../img/MachineCNCVertical.webp";
import imgHot from "../../img/MachineHot.webp";
import imgCoil from "../../img/MachineCoil.webp";
import imgPlastic from "../../img/MachinePlastic.webp";
import imgSpecial from "../../img/MachineSpecial.webp";

class Machine extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="machine">
        <h3 data-aos="fade-down">{t("machine")}</h3>
        <div className="content" data-aos="zoom-in">
          <div className="card">
            <div
              className="mainHood"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <h3>{t("machine-1-hood")}</h3>
            </div>
            <figure data-aos="fade-right" data-aos-delay="300">
              <img src={imgCNC} alt="MACHINE" />
            </figure>
            <div className="txt">
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-1-desc-1")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-1-desc-2")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-1-desc-3")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-1-desc-4")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-1-desc-5")}
              </p>
            </div>
          </div>
          <div className="card">
            <div
              className="mainHood"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <h3>{t("machine-2-hood")}</h3>
            </div>
            <figure data-aos="fade-right" data-aos-delay="300">
              <img src={imgCNCVertical} alt="MACHINE" />
            </figure>
            <div className="txt">
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-2-desc-1")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-2-desc-2")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-2-desc-3")}
              </p>
            </div>
          </div>
          <div className="card">
            <div
              className="mainHood"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <h3>{t("machine-3-hood")}</h3>
            </div>
            <figure data-aos="fade-right" data-aos-delay="300">
              <img src={imgHot} alt="MACHINE" />
            </figure>
            <div className="txt">
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-3-desc-1")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-3-desc-2")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-3-desc-3")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-3-desc-4")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-3-desc-5")}
              </p>
            </div>
          </div>
          <div className="card">
            <div
              className="mainHood"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <h3>{t("machine-4-hood")}</h3>
            </div>
            <figure data-aos="fade-right" data-aos-delay="300">
              <img src={imgCoil} alt="MACHINE" />
            </figure>
            <div className="txt">
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-4-desc-1")}
              </p>
            </div>
          </div>
          <div className="card">
            <div
              className="mainHood"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <h3>{t("machine-5-hood")}</h3>
            </div>
            <figure data-aos="fade-right" data-aos-delay="300">
              <img src={imgPlastic} alt="MACHINE" />
            </figure>
            <div className="txt">
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-5-desc-1")}
              </p>
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-5-desc-2")}
              </p>
            </div>
          </div>
          <div className="card">
            <div
              className="mainHood"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <h3>{t("machine-6-hood")}</h3>
            </div>
            <figure data-aos="fade-right" data-aos-delay="300">
              <img src={imgSpecial} alt="MACHINE" />
            </figure>
            <div className="txt">
              <p data-aos="fade-right" data-aos-delay="300">
                {t("machine-6-desc-1")}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Machine);
