import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import * as app from "../../js/app.js";
import $ from "jquery";
import imgLogo from "../../img/Logo.webp";

class Navigation extends Component {
  changeLanguage = () => {
    if (localStorage.getItem("i18nextLng") === "en") {
      $("nav").removeClass("en");
      $("html").attr("lang", "tr");
      this.props.i18n.changeLanguage("tr");
    } else {
      $("nav").addClass("en");
      $("html").attr("lang", "en");
      this.props.i18n.changeLanguage("en");
    }
  };

  componentDidMount() {
    app.activePage(window.location.pathname);

    // Dilin Ilk Acilista Yuklenmesi
    if (localStorage.getItem("i18nextLng") === "en") {
      $("nav").addClass("en");
      $("html").attr("lang", "en");
    } else {
      $("nav").removeClass("en");
      $("html").attr("lang", "tr");
    }
  }

  render() {
    const { t } = this.props;

    return (
      <nav className="" data-aos="fade-right">
        <div className="nav_img">
          <Link to="/" onClick={() => app.activePage("/")}>
            <figure>
              <img src={imgLogo} alt="LOGO" />
            </figure>
          </Link>
        </div>
        <span
          className="mobile"
          id="mobile-nav"
          onClick={() => app.mobileNav()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" />
          </svg>
        </span>
        <div className="nav_right multi ">
          <div className="pages">
            <Link
              to="/"
              className="naviLink home"
              onClick={() => app.activePage("/")}
            >
              {t("home")}
            </Link>
            <Link
              to="/about"
              className="naviLink about"
              onClick={() => app.activePage("/about")}
            >
              {t("about")}
            </Link>
            <Link
              to="/machine-track"
              className="naviLink machine"
              onClick={() => app.activePage("/machine-track")}
            >
              {t("machine")}
            </Link>
            <Link
              to="/references"
              className="naviLink reference"
              onClick={() => app.activePage("/references")}
            >
              {t("our-references")}
            </Link>
            <Link
              to="/contact"
              className="naviLink contact"
              onClick={() => app.activePage("/contact")}
            >
              {t("contact")}
            </Link>
            <a
              href="tel:+90 216 540 41 45"
              className="callNow"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>{t("nav-call")}</p>
            </a>
          </div>
          <div className="multilang" onClick={() => this.changeLanguage()}>
            <span>TR</span>
            <span>EN</span>
          </div>
        </div>
      </nav>
    );
  }
}

export default withTranslation()(Navigation);
