import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import KVKK from "../pages/KVKK.jsx";
import * as app from "../../js/app.js";
import imgAzelirLogo from "../../img/AzelirBrevo-EmailSignature.png";
import imgLogo from "../../img/Logo.webp";
import $ from "jquery";

class Footer extends Component {
  state = { showModal: false };

  handleOpenModal = () => {
    this.setState({ showModal: true }, () => {
      window.dispatchEvent(new Event("hashchange")); // Hash değişimini tetikle
    });
    $("body").css("overflow", "hidden");
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
    $("body").css("overflow", "auto");
  };

  componentDidMount() {
    if (window.location.hash) {
      this.handleOpenModal();
    }
  }

  render() {
    const { t } = this.props;
    const { showModal } = this.state;
    return (
      <footer>
        <KVKK show={showModal} onClose={this.handleCloseModal} />
        <div className="footer_top">
          <div className="footer_img">
            <Link to="/" onClick={() => app.activePage("/")}>
              <figure>
                <img src={imgLogo} alt="logo" />
              </figure>
            </Link>
          </div>
          <div className="footer_right">
            <div className="footer_contact">
              <h3>{t("contact")}</h3>
              <a
                href="https://www.google.com/maps/place/CBC+End%C3%BCstri/@41.005394,29.162397,16z/data=!4m6!3m5!1s0x14cacf3d8f365737:0xbe57fa15ea9bc178!8m2!3d41.005394!4d29.1623969!16s%2Fg%2F11bzzq3cq0?hl=tr&entry=ttu&g_ep=EgoyMDI0MTAwOC4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dudullu OSB Mah. İmes Sanayi Sit. A Blk. 103 Sok. No: 36
                Ümraniye / İSTANBUL
              </a>
              <a href="tel:+90 216 540 41 45" rel="noopener noreferrer">
                +90 216 540 41 45
              </a>
              <a href="mailto:info@cbcendustri.com" rel="noopener noreferrer">
                info@cbcendustri.com
              </a>
            </div>
            <div className="footer_offer">
              <div className="offer_content">
                <h3>{t("offer")}</h3>
                <p>{t("offer-desc")}</p>
                <a
                  href="tel:+90 216 540 41 45"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  216 540 41 45
                </a>
              </div>
              <div className="offer_btn">
                <Link
                  to="contact"
                  className="btn"
                  onClick={() => app.activePage("/contact")}
                >
                  <p>{t("contact-us")}</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="footer_kvkk">
            <Link to="#clarification-text" onClick={this.handleOpenModal}>
              {t("clarification")}
            </Link>
            <Link to="#contact-clarification" onClick={this.handleOpenModal}>
              {t("contact-clarification")}
            </Link>
            <Link to="#camera-clarification" onClick={this.handleOpenModal}>
              {t("camera-clarification")}
            </Link>
            <Link to="#cookie-policy" onClick={this.handleOpenModal}>
              {t("cookie")}
            </Link>
            <Link
              to="#application-form-to-the-data-controller"
              onClick={this.handleOpenModal}
            >
              {t("data")}
            </Link>
          </div>
          <div className="footer_copyright">
            <div className="copy">
              <p>
                {t("copyright")} {new Date().getFullYear()}
              </p>
            </div>
            <div className="healed">
              <a
                href="https://www.azelirbrevo.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>healed by</p>
                <figure>
                  <img src={imgAzelirLogo} alt="Azelir Brevo" />
                </figure>
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);
