import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class ContactFormClarificationText extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="contactFormClarificationText main-modal">
        <h2>{t("contact-form-1")}</h2>
        <div className="content">
          <p style={{ marginBottom: "20px" }}>{t("contact-form-2")}</p>
          <p style={{ marginBottom: "20px" }}>{t("contact-form-3")}</p>
          <p style={{ marginBottom: "20px" }}>{t("contact-form-4")}</p>
          <p style={{ marginBottom: "20px" }}>{t("contact-form-5")}</p>
          <p>{t("contact-form-6")}</p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ContactFormClarificationText);
