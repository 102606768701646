import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import img from "../../img/About.webp";
import imgCompany from "../../img/Company.webp";
import imgISO9001 from "../../img/ISO-9001.webp";
import imgISO10002 from "../../img/ISO-10002.webp";
import imgMarkaTescil from "../../img/Marka-Tescil.webp";

class About extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="about">
        <div className="about-in">
          <h3 data-aos="fade-down">{t("about")}</h3>
          <div className="desc" data-aos="fade-down" data-aos-delay="300">
            <div className="txt">
              <p data-aos="fade-right" data-aos-delay="600">
                {t("about-desc-1")}
              </p>
              <p data-aos="fade-right" data-aos-delay="700">
                {t("about-desc-2")}
              </p>
            </div>
            <figure data-aos="fade-left" data-aos-delay="600">
              <img src={imgCompany} alt="COMPANY" />
            </figure>
          </div>
        </div>
        <div className="mission-vision">
          <figure>
            <div className="filter"></div>
            <img src={img} alt="BACKGROUND_IMAGE" />
          </figure>
          <div className="mainHood" data-aos="fade-right">
            <h3>{t("mission-vision")}</h3>
          </div>
          <div className="txt">
            <p data-aos="fade-down">{t("mission-vision-desc-1")}</p>
            <p data-aos="fade-down" data-aos-delay="100">
              {t("mission-vision-desc-2")}
            </p>
          </div>
          <div className="values">
            <div className="mainHood" data-aos="fade-left">
              <h3>{t("values")}</h3>
            </div>
            <div className="contents">
              <div className="content" data-aos="zoom-in" data-aos-delay="100">
                <p>{t("values-desc-1")}</p>
              </div>
              <div className="content" data-aos="zoom-in" data-aos-delay="200">
                <p>{t("values-desc-2")}</p>
              </div>
              <div className="content" data-aos="zoom-in" data-aos-delay="300">
                <p>{t("values-desc-3")}</p>
              </div>
              <div className="content" data-aos="zoom-in" data-aos-delay="400">
                <p>{t("values-desc-4")}</p>
              </div>
              <hr />
              <div className="content" data-aos="zoom-in" data-aos-delay="100">
                <p>{t("values-desc-5")}</p>
              </div>
              <div className="content" data-aos="zoom-in" data-aos-delay="200">
                <p>{t("values-desc-6")}</p>
              </div>
              <div className="content" data-aos="zoom-in" data-aos-delay="300">
                <p>{t("values-desc-7")}</p>
              </div>
              <div className="content" data-aos="zoom-in" data-aos-delay="400">
                <p>{t("values-desc-8")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="documents">
          <div className="mainHood" data-aos="fade-right">
            <h3>{t("documents")}</h3>
          </div>
          <div className="content" data-aos="zoom-in">
            <div className="document" data-aos="zoom-in" data-aos-delay="200">
              <figure>
                <img src={imgISO10002} alt="DOCUMENT" />
              </figure>
              <div className="txt">
                <h4>{t("documents-hood-1")}</h4>
                <p>{t("documents-desc-1")}</p>
              </div>
            </div>
            <div className="document" data-aos="zoom-in" data-aos-delay="300">
              <figure>
                <img src={imgISO9001} alt="DOCUMENT" />
              </figure>
              <div className="txt">
                <h4>{t("documents-hood-2")}</h4>
                <p>{t("documents-desc-2")}</p>
              </div>
            </div>
            <div className="document" data-aos="zoom-in" data-aos-delay="400">
              <figure>
                <img src={imgMarkaTescil} alt="DOCUMENT" />
              </figure>
              <div className="txt">
                <h4>{t("documents-hood-3")}</h4>
                <p>{t("documents-desc-3")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(About);
