import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class ClarificationText extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="clarification main-modal">
        <h2>{t("clarification-1")}</h2>
        <div className="content">
          <p>{t("clarification-2")}</p>
          <h4>{t("clarification-3")}</h4>
          <p>{t("clarification-4")}</p>

          <ul>
            <li style={{ listStyleType: "lower-roman" }}>
              <p>{t("clarification-5")}</p>
            </li>
            <li style={{ listStyleType: "lower-roman" }}>
              <p>{t("clarification-6")}</p>
            </li>
            <li style={{ listStyleType: "lower-roman" }}>
              <p>{t("clarification-7")}</p>
            </li>
            <li style={{ listStyleType: "lower-roman" }}>
              <p>{t("clarification-8")}</p>
            </li>
            <li style={{ listStyleType: "lower-roman" }}>
              <p>{t("clarification-9")}</p>
            </li>
          </ul>

          <p>{t("clarification-10")}</p>

          <ul>
            <li style={{ listStyleType: "upper-alpha" }}>
              <h4>{t("clarification-11")}</h4>
              <p>{t("clarification-12")}</p>
              <ul>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-13")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-14")}</p>
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "upper-alpha" }}>
              <h4>{t("clarification-15")}</h4>
              <p>{t("clarification-16")}</p>
              <ul>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-17")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-18")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-19")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-20")}</p>
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "upper-alpha" }}>
              <h4>{t("clarification-21")}</h4>
              <p>{t("clarification-22")}</p>
              <ul>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-23")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-24")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-25")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-26")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-27")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-28")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-29")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-30")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-31")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-32")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-33")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-34")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-35")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-36")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-37")}</p>
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "upper-alpha" }}>
              <h4>{t("clarification-38")}</h4>
              <p>{t("clarification-39")}</p>
              <ul>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-40")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-41")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-42")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-43")}</p>
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "upper-alpha" }}>
              <h4>{t("clarification-44")}</h4>
              <p>{t("clarification-45")}</p>
              <ul>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-46")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-47")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-48")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-49")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-50")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-51")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-52")}</p>
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "upper-alpha" }}>
              <h4>{t("clarification-53")}</h4>
              <p>{t("clarification-54")}</p>
              <ul>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-55")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-56")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-57")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-58")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-59")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-60")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <p>{t("clarification-61")}</p>
                </li>
              </ul>
            </li>
          </ul>

          <ul>
            <li style={{ listStyleType: "decimal" }}>
              <h4>{t("clarification-62")}</h4>
              <p>{t("clarification-63")}</p>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              <h4>{t("clarification-64")}</h4>
              <p>{t("clarification-65")}</p>
              <ul>
                <li style={{ listStyleType: "lower-roman" }}>
                  <p>{t("clarification-66")}</p>
                </li>
                <li style={{ listStyleType: "lower-roman" }}>
                  <p>{t("clarification-67")}</p>
                </li>
                <li style={{ listStyleType: "lower-roman" }}>
                  <p>{t("clarification-68")}</p>
                </li>
                <li style={{ listStyleType: "lower-roman" }}>
                  <p>{t("clarification-69")}</p>
                </li>
                <li style={{ listStyleType: "lower-roman" }}>
                  <p>{t("clarification-70")}</p>
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              <h4>{t("clarification-71")}</h4>
              <ul>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <strong>{t("clarification-72")}</strong>
                  <p>{t("clarification-73")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <strong>{t("clarification-74")}</strong>
                  <p>{t("clarification-75")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <strong>{t("clarification-76")}</strong>
                  <p>{t("clarification-77")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <strong>{t("clarification-78")}</strong>
                  <p>{t("clarification-79")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <strong>{t("clarification-80")}</strong>
                  <p>{t("clarification-81")}</p>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  <strong>{t("clarification-82")}</strong>
                  <p>{t("clarification-83")}</p>
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              <h4>{t("clarification-84")}</h4>
              <p>{t("clarification-85")}</p>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              <h4>{t("clarification-86")}</h4>
              <p style={{ display: "inline" }}>{t("clarification-87")}</p>
              <Link
                className="link"
                to="https://www.cbcendustri.com/"
                target="_blank"
              >
                https://www.cbcendustri.com/
              </Link>
              <p style={{ display: "inline" }}>{t("clarification-88")}</p>
            </li>
          </ul>

          <ul>
            <li style={{ display: "flex", gap: "10px" }}>
              <strong>{t("clarification-89")}</strong>
              <p>Cbc Endüstri San. Tic. Ltd. Şti.</p>
            </li>
            <li style={{ display: "flex", gap: "10px" }}>
              <strong>{t("clarification-90")}</strong>
              <p>Dudullu OSB İmes San. Sit. 103 Sk. No:36 Ümraniye /İstanbul</p>
            </li>
            <li style={{ display: "flex", gap: "10px" }}>
              <strong>{t("clarification-91")}</strong>
              <p>kvkk@cbcendustri.com</p>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ClarificationText);
