import $ from "jquery";

$(document).ready(function () {
  var scrollPoss = $(window).scrollTop();
  var scrollCount = 0;

  $(window).scroll(function () {
    // Nav
    var scrollPoss2 = $(window).scrollTop();
    if (scrollPoss >= scrollPoss2) {
      $("nav").css("top", "0");
      scrollCount = 0;
    } else {
      scrollCount++;
      if (scrollCount >= 50) {
        $("nav").css("top", "-100%");
      }
    }
    if (scrollPoss >= 500) {
      $("nav").addClass("wide");
    } else {
      $("nav").removeClass("wide");
    }

    // Scroll Top
    if (scrollPoss >= scrollPoss2) {
      if (scrollPoss2 >= 500) {
        $("#scrollTop").css("right", "0");
      } else {
        $("#scrollTop").css("right", "-100%");
        $("html").css("scroll-behavior", "auto");
      }
    } else {
      $("#scrollTop").css("right", "-100%");
      $("html").css("scroll-behavior", "auto");
    }

    scrollPoss = scrollPoss2;

    // Homepage Img
    if (scrollPoss >= 0 && scrollPoss <= 1000) {
      $(".homepage .parallax_img img").css({
        transform: `scale(${1 + scrollPoss / 5000})`,
      });
    }

    // About Img
    if (scrollPoss >= 100 && scrollPoss <= 1500) {
      $(".about .mission-vision figure img").css({
        transform: `scale(${1 + scrollPoss / 10000})`,
      });
    }
  });
});

export function mobileNav() {
  if ($("nav").hasClass("active")) {
    $("nav").removeClass("active");
  } else {
    $("nav").addClass("active");
  }
}

export function scrollTop() {
  $("html").css("scroll-behavior", "smooth");
  $(window).scrollTop(0);
}

export function activePage(path) {
  $("nav .pages .naviLink").removeClass("active");
  switch (path) {
    case "/":
      $("nav .naviLink.home").addClass("active");
      break;
    case "/about":
      $("nav .naviLink.about").addClass("active");
      break;
    case "/machine-track":
      $("nav .naviLink.machine").addClass("active");
      break;
    case "/references":
      $("nav .naviLink.reference").addClass("active");
      break;
    case "/contact":
      $("nav .naviLink.contact").addClass("active");
      break;
    default:
      $("nav .naviLink").removeClass("active");
      break;
  }
}
