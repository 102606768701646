import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class ApplicationFormtotheDataController extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="applicationFormtotheDataController main-modal">
        <h2>{t("data-1")}</h2>
        <div className="content">
          <ul>
            <li style={{ listStyleType: "decimal" }}>
              <h4>{t("data-2")}</h4>
              <p>{t("data-3")}</p>
              <p>{t("data-4")}</p>
              <p>{t("data-5")}</p>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              <h4>{t("data-6")}</h4>
              <p>{t("data-7")}</p>
              <ul>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("data-8")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("data-9")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("data-10")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("data-11")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("data-12")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("data-13")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("data-14")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("data-15")}</p>
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              <h4>{t("data-16")}</h4>
              <p>{t("data-17")}</p>
              <ul>
                <p>{t("data-18")}</p>
                <li style={{ listStyleType: "disc" }}>
                  <p>{t("data-19")}</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>{t("data-20")}</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>{t("data-21")}</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>{t("data-22")}</p>
                </li>
              </ul>
              <p>{t("data-23")}</p>
              <div
                className="table-div"
                style={{ overflowX: "auto", marginBottom: "15px" }}
              >
                <table style={{ marginBottom: "0" }}>
                  <thead>
                    <tr>
                      <th>
                        <strong>{t("data-24")}</strong>
                      </th>
                      <th>
                        <strong>{t("data-25")}</strong>
                      </th>
                      <th>
                        <strong>{t("data-26")}</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>{t("data-27")}</p>
                      </td>
                      <td>
                        <p>{t("data-28")}</p>
                      </td>
                      <td>
                        <p>{t("adress")}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>{t("data-29")}</p>
                      </td>
                      <td>
                        <p>{t("data-30")}</p>
                      </td>
                      <td>
                        <p>{t("adress")}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>{t("data-31")}</p>
                      </td>
                      <td>
                        <p>{t("data-32")}</p>
                      </td>
                      <td>
                        <Link to="mailto:kvkk@cbcendustri.com" className="link">
                          kvkk@cbcendustri.com
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p>{t("data-33")}</p>
              <p>{t("data-34")}</p>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              <h4>{t("data-35")}</h4>
              <ul>
                <li style={{ listStyleType: "upper-alpha" }}>
                  <h4>{t("data-36")}</h4>
                  <table>
                    <tbody>
                      <tr style={{ width: "100%" }}>
                        <td style={{ width: "50%" }}>
                          <strong>{t("data-37")}</strong>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td style={{ width: "50%" }}>
                          <strong>{t("data-38")}</strong>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td style={{ width: "50%" }}>
                          <strong>{t("data-39")}</strong>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td style={{ width: "50%" }}>
                          <strong>{t("data-40")}</strong>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td style={{ width: "50%" }}>
                          <strong>{t("data-41")}</strong>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td style={{ width: "50%" }}>
                          <strong>{t("data-42")}</strong>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li style={{ listStyleType: "upper-alpha" }}>
                  <h4>{t("data-43")}</h4>
                  <p>{t("data-44")}</p>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <input type="checkbox" />
                            <p>{t("data-45")}</p>
                          </div>
                          <br />
                          <div style={{ display: "flex", gap: "5px" }}>
                            <input type="checkbox" />
                            <p>{t("data-46")}</p>
                          </div>
                          <br />
                          <div style={{ display: "flex", gap: "5px" }}>
                            <input type="checkbox" />
                            <p>{t("data-47")}</p>
                          </div>
                          <br />
                        </td>
                        <td>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <input type="checkbox" />
                            <p>{t("data-48")}</p>
                          </div>
                          <br />
                          <div style={{ display: "flex", gap: "5px" }}>
                            <input type="checkbox" />
                            <p>{t("data-49")}</p>
                          </div>
                          <br />
                          <div style={{ display: "flex", gap: "5px" }}>
                            <input type="checkbox" />
                            <p>{t("data-50")}</p>
                          </div>
                          <br />
                          <div style={{ display: "flex", gap: "5px" }}>
                            <input type="checkbox" />
                            <p>{t("data-51")}</p>
                          </div>
                          <br />
                          <p>
                            . . . . . . . . . . . . . . . . . . . . . . . . . .
                            . . . . . . . . . . . . . . . . . . . . . . . . . .
                            . . . . .
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <p>{t("data-52")}</p>
                          <p>
                            {t("data-53")} . . . . . . . . . . . . . . . . . . .
                            . . . . . . . . . . . . . . . . . . .
                          </p>
                          <p>
                            {t("data-54")} . . . . . . . . . . . . . . . . . . .
                            . . . . . . . . . . . . . . . . . . . . . . . . . .
                            . . . . . . . . . . . . . . . . . . . . . . . . . .
                            . . . . .
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              <h4>{t("data-55")}</h4>
              <p>
                <strong>{t("data-56")}</strong>
              </p>
              <table style={{ width: "100%", height: "500px" }}>
                <tbody>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <ul>
                <p>
                  <strong>{t("data-57")}</strong>
                </p>
                <li>
                  <p>
                    <strong>{t("data-58")}</strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>{t("data-59")}</strong>
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ApplicationFormtotheDataController);
