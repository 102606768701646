import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import imgChrome from "../../../img/chrome.webp";
import imgFireFox from "../../../img/firefox.webp";
import imgSafari from "../../../img/safari.webp";
import imgEdge from "../../../img/edge.webp";
import imgExplorer from "../../../img/explorer.webp";

class CookiePolicy extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="cookiePolicy main-modal">
        <h2>{t("cookie-1")}</h2>
        <div className="content">
          <p style={{ marginBottom: "20px" }}>{t("cookie-2")}</p>
          <p style={{ marginBottom: "20px" }}>{t("cookie-3")}</p>
          <p style={{ marginBottom: "20px" }}>{t("cookie-4")}</p>
          <ul>
            <li>
              <strong>{t("cookie-5")}</strong>
              <p style={{ display: "inline" }}>{t("cookie-6")}</p>
            </li>
            <li>
              <strong>{t("cookie-7")}</strong>
              <p style={{ display: "inline" }}>{t("cookie-8")}</p>
            </li>
            <li>
              <strong>{t("cookie-9")}</strong>
              <p style={{ display: "inline" }}>{t("cookie-10")}</p>
            </li>
            <li>
              <strong>{t("cookie-11")}</strong>
              <p style={{ display: "inline" }}>{t("cookie-12")}</p>
            </li>
            <li>
              <strong>{t("cookie-13")}</strong>
              <p style={{ display: "inline" }}>{t("cookie-14")}</p>
            </li>
          </ul>
          <p>{t("cookie-15")}</p>
          <ul>
            <strong>{t("cookie-16")}</strong>
            <li>
              <strong>Google:</strong>
              <p style={{ display: "inline" }}>{t("cookie-17")}</p>
            </li>
            <li>
              <strong>Google Analytics:</strong>
              <p style={{ display: "inline" }}>{t("cookie-18")}</p>
            </li>
            <li>
              <strong>Google Translate:</strong>
              <p style={{ display: "inline" }}>{t("cookie-19")}</p>
            </li>
            <li>
              <strong>Google Tag Manager:</strong>
              <p style={{ display: "inline" }}>{t("cookie-20")}</p>
            </li>
          </ul>
          <p style={{ display: "inline" }}>{t("cookie-21")}</p>
          <Link
            to="https://allaboutcookies.org/"
            className="link"
            target="_blank"
          >
            " www.allaboutcookies.org "
          </Link>
          <p style={{ display: "inline" }}>{t("cookie-22")}</p>
          <Link
            to="https://youronlinechoices.eu/"
            className="link"
            target="_blank"
          >
            " www.youronlinechoices.eu "
          </Link>
          <p style={{ display: "inline" }}>{t("cookie-23")}</p>
          <h2 style={{ width: "100%", marginTop: "20px" }}>{t("cookie-24")}</h2>
          <p>{t("cookie-25")}</p>
          <ul>
            <li>
              <strong>Chrome</strong>
              <ul>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-26")}.</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-27")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-28")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-29")}</p>
                </li>
                <li>
                  <img src={imgChrome} alt="CHROME" />
                </li>
              </ul>
            </li>
            <li>
              <strong>Fire fox</strong>
              <ul>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-30")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-31")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-32")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-33")}</p>
                </li>
                <li>
                  <img src={imgFireFox} alt="FIREFOX" />
                </li>
              </ul>
            </li>
            <li>
              <strong>Safari</strong>
              <ul>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-34")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-35")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-36")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-37")}</p>
                </li>
                <li>
                  <img src={imgSafari} alt="SAFARI" />
                </li>
              </ul>
            </li>
            <li>
              <strong>Edge</strong>
              <ul>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-38")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-39")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-40")}</p>
                </li>
                <li>
                  <img src={imgEdge} alt="EDGE" />
                </li>
              </ul>
            </li>
            <li>
              <strong>Internet Explorer</strong>
              <ul>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-41")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-42")}</p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  <p>{t("cookie-43")}</p>
                </li>
                <li>
                  <img src={imgExplorer} alt="EXPLORER" />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CookiePolicy);
