import React, { Component } from "react";
import { Link } from "react-router-dom";
import ClarificationText from "./docs/ClarificationText";
import ApplicationFormtotheDataController from "./docs/ApplicationFormtotheDataController";
import CookiePolicy from "./docs/CookiePolicy";
import ContactFormClarificationText from "./docs/ContactFormClarificationText";
import SecurityCamerasClarificationText from "./docs/SecurityCamerasClarificationText";

export default class KVKK extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
    };
  }

  updateContent = () => {
    const hash = window.location.hash;

    let content;
    switch (hash) {
      case "#clarification-text":
        content = <ClarificationText />;
        break;
      case "#contact-clarification":
        content = <ContactFormClarificationText />;
        break;
      case "#camera-clarification":
        content = <SecurityCamerasClarificationText />;
        break;
      case "#cookie-policy":
        content = <CookiePolicy />;
        break;
      case "#application-form-to-the-data-controller":
        content = <ApplicationFormtotheDataController />;
        break;
      default:
        content = (
          <div>
            <h2>Default Content</h2>
            <p>This is the default content when no section is selected.</p>
          </div>
        );
    }

    this.setState({ content });
  };

  componentDidMount() {
    window.addEventListener("hashchange", this.updateContent);
    this.updateContent(); // Initial content setup
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.updateContent);
  }
  render() {
    const { show, onClose } = this.props;
    const { content } = this.state;

    if (!show) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <Link
            to={window.location.pathname}
            onClick={onClose}
            className="modal-close"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </Link>
          {content}
        </div>
      </div>
    );
  }
}
