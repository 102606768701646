import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import imgReferanceEska from "../../img/logoEska.webp";
import imgReferanceBass from "../../img/logoBass.webp";
import imgReferanceUskon from "../../img/logoUskon.webp";
import imgReferanceUnuvar from "../../img/logoUnuvar.webp";
import imgReferanceDuravis from "../../img/logoDuravis.webp";
import imgReferanceNazorati from "../../img/logoNazorati.webp";
import imgReferanceSenseMore from "../../img/logoSenseMore.webp";
import imgReferanceCigTeknik from "../../img/logoCigTeknik.webp";
import { Link } from "react-router-dom";

class References extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="references-page">
        <h3 data-aos="fade-down">{t("our-references")}</h3>
        <div className="content">
          <div className="reference" data-aos="fade-right" data-aos-delay="200">
            <Link to="https://www.eskavalve.com/" target="_blank">
              <figure>
                <img src={imgReferanceEska} alt="REFERENCE" />
              </figure>
            </Link>
          </div>
          <div
            className="reference dark"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            <Link to="https://www.bass.com.tr/" target="_blank">
              <figure>
                <img src={imgReferanceBass} alt="REFERENCE" />
              </figure>
            </Link>
          </div>
          <div className="reference" data-aos="fade-right" data-aos-delay="400">
            <Link to="https://www.us-kon.com.tr/" target="_blank">
              <figure>
                <img src={imgReferanceUskon} alt="REFERENCE" />
              </figure>
            </Link>
          </div>
          <div className="reference" data-aos="fade-right" data-aos-delay="500">
            <Link to="https://unuvar.com/" target="_blank">
              <figure>
                <img src={imgReferanceUnuvar} alt="REFERENCE" />
              </figure>
            </Link>
          </div>
          <div className="reference" data-aos="fade-right" data-aos-delay="600">
            <Link to="http://www.duravis.com/" target="_blank">
              <figure>
                <img src={imgReferanceDuravis} alt="REFERENCE" />
              </figure>
            </Link>
          </div>
          <div className="reference" data-aos="fade-right" data-aos-delay="200">
            <Link to="https://nazorati.com/" target="_blank">
              <figure>
                <img src={imgReferanceNazorati} alt="REFERENCE" />
              </figure>
            </Link>
          </div>
          <div className="reference" data-aos="fade-right" data-aos-delay="300">
            <Link to="https://sensemore.io/" target="_blank">
              <figure>
                <img src={imgReferanceSenseMore} alt="REFERENCE" />
              </figure>
            </Link>
          </div>
          <div className="reference" data-aos="fade-right" data-aos-delay="400">
            <Link to="https://www.cigteknik.com/" target="_blank">
              <figure>
                <img src={imgReferanceCigTeknik} alt="REFERENCE" />
              </figure>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(References);
