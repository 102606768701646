import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import * as app from "../../js/app.js";

class NotFound extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="notFound">
        <h1 data-aos="fade-down">Oops!</h1>
        <h3 data-aos="fade-right" data-aos-delay="500">
          {t("404")}
        </h3>
        <h5 data-aos="fade-left" data-aos-delay="1000">
          {t("404-desc")}
        </h5>
        <Link
          to="/"
          className="link"
          data-aos="zoom-in"
          data-aos-delay="1500"
          onClick={() => app.activePage("/")}
        >
          <p>{t("go-to-home")}</p>
        </Link>
      </div>
    );
  }
}

export default withTranslation()(NotFound);
