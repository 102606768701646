import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Form from "../form/Form";

class Contact extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="contact">
        <h3 data-aos="fade-down">{t("contact")}</h3>
        <div className="content">
          <div className="form" data-aos="fade-right">
            <Form />
          </div>
          <div className="map" data-aos="fade-left">
            <iframe
              title="google map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6021.815343210661!2d29.162397!3d41.005394!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cacf3d8f365737%3A0xbe57fa15ea9bc178!2sCBC%20End%C3%BCstri!5e0!3m2!1str!2sus!4v1728294652188!5m2!1str!2sus"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Contact);
