import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import * as app from "../../js/app.js";
import imgFirst from "../../img/Homepage.webp";
import imgGallery1 from "../../img/Gallery1.webp";
import imgGallery2 from "../../img/Gallery2.webp";
import imgGallery3 from "../../img/Gallery3.webp";
import imgGallery4 from "../../img/Gallery4.webp";
import imgGallery5 from "../../img/Gallery5.webp";
import imgGallery6 from "../../img/Gallery6.webp";
import imgGallery7 from "../../img/Gallery7.webp";
import imgReferanceEska from "../../img/logoEska.webp";
import imgReferanceBass from "../../img/logoBass.webp";
import imgReferanceUskon from "../../img/logoUskon.webp";
import imgReferanceUnuvar from "../../img/logoNazorati.webp";
import imgReferanceDuravis from "../../img/logoDuravis.webp";
import video from "../../img/video.mp4";

class Homepage extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="homepage">
        <div className="home_first">
          <div className="parallax_img">
            <div className="effect"></div>
            <img src={imgFirst} alt="backgroundImage" className="deneme-img" />
          </div>
          <div className="inclusive">
            <div className="txt">
              <h1 data-aos="fade-left" data-aos-delay="300">
                {t("homepage-first")}
              </h1>
              <h3 data-aos="fade-down" data-aos-delay="600">
                {t("homepage-first-desc")}
              </h3>
            </div>
            <div className="btns" data-aos="fade-down" data-aos-delay="900">
              <Link
                to="machine-track"
                className="btn machine_btn"
                onClick={() => app.activePage("/machine-track")}
              >
                <p>{t("machine")}</p>
              </Link>
              <Link
                to="contact"
                className="btn contact_btn"
                onClick={() => app.activePage("/contact")}
              >
                <p>{t("contact-us")}</p>
              </Link>
            </div>
          </div>
          <div className="details" data-aos="fade-up">
            <div className="detail">
              <figure>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 2048 2048"
                >
                  <path
                    fill="currentColor"
                    d="M1968 1095q38 36 59 84t21 101t-20 99t-55 82t-81 55t-100 20q-21 0-42-3l-471 235q1 6 1 12t0 12q0 53-20 99t-55 82t-81 55t-100 20q-52 0-98-20t-82-54t-56-81t-21-98v-13q0-6 2-14l-471-235q-21 3-42 3q-53 0-99-20t-82-55t-55-81t-20-100q0-71 36-131t99-94l175-642q-54-69-54-157q0-53 20-99t55-82t81-55T512 0q69 0 128 34t94 94h580q35-60 94-94t128-34q53 0 99 20t82 55t55 81t20 100q0 42-13 81t-39 73zm-432 185q0-55 22-105t64-86l-449-337l-663 497l2 31zM512 512q-47 0-92-17l-144 530q55 5 103 31t82 70l606-454l-350-262q-37 48-90 75t-115 27m1241 515q8-2 18-2t19-1h10q5 0 10 1l-177-532q-49 19-97 19q-20 0-39-3l-217 163zm-217-899q-27 0-50 10t-40 27t-28 41t-10 50q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50q0-27-10-50t-27-40t-41-28t-50-10m-256 128H768l-2 31l407 305l193-145q-42-36-64-86t-22-105M512 128q-27 0-50 10t-40 27t-28 41t-10 50q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50q0-27-10-50t-27-40t-41-28t-50-10M128 1280q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50q0-27-10-50t-27-40t-41-28t-50-10q-27 0-50 10t-40 27t-28 41t-10 50m896 640q27 0 50-10t40-27t28-41t10-50q0-27-10-50t-27-40t-41-28t-50-10q-27 0-50 10t-40 27t-28 41t-10 50q0 27 10 50t27 40t41 28t50 10m0-384q63 0 119 29t92 82l375-187q-11-12-21-25t-19-27H478q-8 14-18 27t-22 25l375 187q35-52 91-81t120-30m768-128q27 0 50-10t40-27t28-41t10-50q0-33-16-62t-44-46l-1 1l-1-1l1-1q-32-19-67-19q-27 0-50 10t-40 27t-28 41t-10 50q0 27 10 50t27 40t41 28t50 10"
                  ></path>
                </svg>
              </figure>
              <div className="txt">
                <div className="no">
                  <p>15</p>
                  <p>+</p>
                </div>
                <p>{t("homepage-transaction")}</p>
              </div>
            </div>
            <div className="detail">
              <figure>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M1.05 21v-2.8q0-.825.425-1.55t1.175-1.1q1.275-.65 2.875-1.1T9.05 14t3.525.45t2.875 1.1q.75.375 1.175 1.1t.425 1.55V21zm2-2h12v-.8q0-.275-.137-.5t-.363-.35q-.9-.45-2.312-.9T9.05 16t-3.187.45t-2.313.9q-.225.125-.362.35t-.138.5zm6-6q-1.65 0-2.825-1.175T5.05 9H4.8q-.225 0-.362-.137T4.3 8.5t.138-.363T4.8 8h.25q0-1.125.55-2.025T7.05 4.55v.95q0 .225.138.363T7.55 6t.363-.137t.137-.363V4.15q.225-.075.475-.112T9.05 4t.525.038t.475.112V5.5q0 .225.138.363T10.55 6t.363-.137t.137-.363v-.95q.9.525 1.45 1.425T13.05 8h.25q.225 0 .363.138t.137.362t-.137.363T13.3 9h-.25q0 1.65-1.175 2.825T9.05 13m0-2q.825 0 1.413-.587T11.05 9h-4q0 .825.588 1.413T9.05 11m7.5 4l-.15-.75q-.15-.05-.287-.112t-.263-.188l-.7.25l-.5-.9l.55-.5v-.6l-.55-.5l.5-.9l.7.25q.1-.1.25-.175t.3-.125l.15-.75h1l.15.75q.15.05.3.125t.25.175l.7-.25l.5.9l-.55.5v.6l.55.5l-.5.9l-.7-.25q-.125.125-.262.188t-.288.112l-.15.75zm.5-1.75q.3 0 .525-.225t.225-.525t-.225-.525t-.525-.225t-.525.225t-.225.525t.225.525t.525.225m1.8-3.25l-.2-1.05q-.225-.075-.412-.187T17.9 8.5l-1.05.35l-.7-1.2l.85-.75q-.05-.125-.05-.2v-.4q0-.075.05-.2l-.85-.75l.7-1.2l1.05.35q.15-.15.338-.263t.412-.187l.2-1.05h1.4l.2 1.05q.225.075.413.188t.337.262l1.05-.35l.7 1.2l-.85.75q.05.125.05.2v.4q0 .075-.05.2l.85.75l-.7 1.2l-1.05-.35q-.15.15-.337.263t-.413.187l-.2 1.05zm.7-2.25q.525 0 .888-.363T20.8 6.5t-.363-.888t-.887-.362t-.888.363t-.362.887t.363.888t.887.362M9.05 19"
                  ></path>
                </svg>
              </figure>
              <div className="txt">
                <div className="no">
                  <p>20</p>
                  <p>+</p>
                </div>
                <p>{t("homepage-worker")}</p>
              </div>
            </div>
            <div className="detail">
              <figure>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 2048 2048"
                >
                  <path
                    fill="currentColor"
                    d="M1792 1280h256v768H1024v-768h256v-256h512zm-384 0h256v-128h-256zm512 384h-128v128h-128v-128h-256v128h-128v-128h-128v256h768zm-768-256v128h768v-128zM896 896q0 93-41 174t-117 136q45 23 85 53t73 67v338q0-79-30-149t-82-122t-123-83t-149-30q-80 0-149 30t-122 82t-83 123t-30 149H0q0-73 20-141t57-129t91-108t118-81q-75-54-116-135t-42-174q0-79 30-149t82-122t122-83t150-30q72 0 137 25t119 74q32-29 71-51q-34-35-52-81t-19-95q0-53 20-99t55-82t81-55t100-20q53 0 99 20t82 55t55 81t20 100q0 49-18 95t-53 81q83 46 135 124q52-78 135-124q-34-35-52-81t-19-95q0-53 20-99t55-82t81-55t100-20q53 0 99 20t82 55t55 81t20 100q0 49-18 95t-53 81q46 25 83 61t62 79t40 94t14 102h-128q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100h-128q0-53-20-99t-55-82t-81-55t-100-20q-49 0-95 18t-81 53q24 42 36 89t12 96m768-640q-27 0-50 10t-40 27t-28 41t-10 50q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50q0-27-10-50t-27-40t-41-28t-50-10m-640 0q-27 0-50 10t-40 27t-28 41t-10 50q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50q0-27-10-50t-27-40t-41-28t-50-10m-512 896q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100q0 53 20 99t55 82t81 55t100 20"
                  ></path>
                </svg>
              </figure>
              <div className="txt">
                <div className="no">
                  <p>50</p>
                  <p>+</p>
                </div>
                <p>{t("homepage-customer")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="why_choose">
          <video src={video} autoPlay loop muted></video>
          <div className="mainHood" data-aos="fade-right">
            <h3>{t("why")}</h3>
          </div>
          <div className="content" data-aos="fade-right">
            <div className="option">
              <h4>{t("why-hood-1")}</h4>
              <p>{t("why-desc-1")}</p>
            </div>
            <div className="option">
              <h4>{t("why-hood-2")}</h4>
              <p>{t("why-desc-2")}</p>
            </div>
            <div className="option">
              <h4>{t("why-hood-3")}</h4>
              <p>{t("why-desc-3")}</p>
            </div>
            <div className="option">
              <h4>{t("why-hood-4")}</h4>
              <p>{t("why-desc-4")}</p>
            </div>
          </div>
        </div>
        <div className="gallery">
          <div className="mainHood" data-aos="fade-right">
            <h3>{t("gallery")}</h3>
          </div>
          <div className="imgs">
            <div className="img" data-aos="fade-down" data-aos-delay="100">
              <div className="filter"></div>
              <figure>
                <img src={imgGallery1} alt="GALLERY" />
              </figure>
            </div>
            <div className="img" data-aos="fade-down" data-aos-delay="200">
              <div className="filter"></div>
              <figure>
                <img src={imgGallery2} alt="GALLERY" />
              </figure>
            </div>
            <div className="img" data-aos="fade-down" data-aos-delay="300">
              <div className="filter"></div>
              <figure>
                <img src={imgGallery3} alt="GALLERY" />
              </figure>
            </div>
            <div className="img" data-aos="fade-down" data-aos-delay="400">
              <div className="filter"></div>
              <figure>
                <img src={imgGallery4} alt="GALLERY" />
              </figure>
            </div>
            <div className="img" data-aos="fade-down" data-aos-delay="500">
              <div className="filter"></div>
              <figure>
                <img src={imgGallery5} alt="GALLERY" />
              </figure>
            </div>
            <div className="img" data-aos="fade-down" data-aos-delay="600">
              <div className="filter"></div>
              <figure>
                <img src={imgGallery6} alt="GALLERY" />
              </figure>
            </div>
            <div className="img" data-aos="fade-down" data-aos-delay="700">
              <div className="filter"></div>
              <figure>
                <img src={imgGallery7} alt="GALLERY" />
              </figure>
            </div>
          </div>
        </div>
        <div className="references">
          <div className="mainHood" data-aos="fade-left">
            <h3>{t("references")}</h3>
          </div>
          <div className="content">
            <div className="referance" data-aos="zoom-in" data-aos-delay="100">
              <figure>
                <img src={imgReferanceEska} alt="referans" />
              </figure>
            </div>
            <div className="referance" data-aos="zoom-in" data-aos-delay="200">
              <figure>
                <img src={imgReferanceBass} alt="referans" />
              </figure>
            </div>
            <div className="referance" data-aos="zoom-in" data-aos-delay="300">
              <figure>
                <img src={imgReferanceUskon} alt="referans" />
              </figure>
            </div>
            <div className="referance" data-aos="zoom-in" data-aos-delay="400">
              <figure>
                <img src={imgReferanceUnuvar} alt="referans" />
              </figure>
            </div>
            <div className="referance" data-aos="zoom-in" data-aos-delay="500">
              <figure>
                <img src={imgReferanceDuravis} alt="referans" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Homepage);
