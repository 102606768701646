import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/root/App";
import { BrowserRouter } from "react-router-dom";
import "alertifyjs/build/css/alertify.min.css";
import "./css/main.css";
import "./js/app.js";
import "./i18n.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
